import eng from './languages/eng';
import ger from './languages/ger';
import fre from './languages/fre';

const langs = {
  eng,
  ger,
  fre,
};

let active = 'ger';

export function changeLanguage(l) {
  active = l;
}

export function t(key) {
  return langs[active][key];
}

<template>
  <form class="ccForm">
    <div>
      <div class="sf sf__input sf__base sf__has-actions sf__has-card-icon">
        <label for="card-number">{{ t('ccNumber') }}</label>
        <!-- card number container -->
        <div
          :class="{
            'sf-__has-error': cardHasError,
          }"
          class="sf--input-container card-number"
          @click="clickCardContainer"
        >
          <div
            id="card-number"
            class="sf--input"
          />
          <div class="sf--actions">
            <span class="sf--action sf--action__card-icon">
              <span class="sf--action-card-wrap">
                <img
                  :src="cardImage"
                  class="sf--card-icon"
                >
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="sf__row">
        <div
          :class="{
            'sf-__has-error': expiryHasError,
          }"
          class="sf sf__input sf__base sf__has-actions sf__has-card-icon expiry-container"
        >
          <label for="expiry">{{ t('expDate') }}</label>
          <div class="sf--input-container expiry">
            <input
              ref="expiryRef"
              v-model="expiry"
              id="expiry"
              name="expiry"
              placeholder="MM / YY"
              type="tel"
              maxlength="7"
              class="sf--input"
              @change="expiryHasError = false"
            >
            <div class="sf--actions">
              <span class="sf--action sf--action__card-icon">
                <span class="sf--action-card-wrap">
                  <img
                    class="sf--card-icon"
                    :src="expiryImage"
                  >
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          :class="{
            'sf-__has-error': cvvHasError,
          }"
          class="sf sf__input sf__base sf__has-actions sf__has-card-icon cvv-container"
          @click="clickCvvContainer"
        >
          <label for="cvv-number">{{ t('cvv') }}</label>
          <div class="sf--input-container cvv">
            <!-- cvv container -->
            <div
              id="cvv-number"
              class="sf--input"
            />
            <div class="sf--actions">
              <span class="sf--action sf--action__card-icon">
                <span class="sf--action-card-wrap">
                  <img
                    class="sf--card-icon"
                    :src="cvvImage"
                  >
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="submit-container">
        <button
          :disabled="hasErrors || expiryHasError || expiry.length !== 7"
          type="button"
          id="go"
          @click="submit"
        >
          {{ t('submit') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';

import { t } from '@/i18n';

import CardEmpty from '@/assets/card-empty.svg';
import CardError from '@/assets/card-error.svg';

import CvvEmpty from '@/assets/cvc-empty.svg';
import CvvError from '@/assets/cvc-error.svg';

function formatExpriy(val) {
  const p1 = parseInt(val[0], 10);
  const p2 = parseInt(val[1], 10);

  return /^\d$/.test(val) && val !== '0' && val !== '1'
    ? `0${val} / `
    : /^\d\d$/.test(val)
      ? p2 > 2 && p1 !== 0 ? `0${p1} / ${p2}` : `${val} / `
      : val;
}

export default defineComponent({
  name: 'CreditCardForm',
  props: {
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['finished'],
  setup(props, { emit }) {
    const secureFieldsRef = ref(null);
    const expiry = ref('');
    const expiryRef = ref(null);
    const paymentMethod = ref(null);
    const cardHasError = ref(false);
    const cvvHasError = ref(false);
    const expiryHasError = ref(false);
    const hasErrors = ref(false);

    function setInputFilter(textbox, inputFilter) {
      ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach((key) => {
        textbox.addEventListener(key, function keyPressed(e) {
          if (/^\d+$/.test(e.key)) {
            this.value = formatExpriy(this.value);
          }

          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          } else {
            this.value = '';
          }
          if (this.oldSelectionEnd === 7 && key === 'keyup' && e.key.match(/^[0-9]$/)) {
            secureFieldsRef.value.focus('cvv');
          }
        });
      });
    }

    function parseExpiryDate() {
      const dateSplit = expiry.value.split(' / ');
      if (dateSplit.length === 2) {
        return {
          expm: parseInt(dateSplit[0], 10),
          expy: parseInt(dateSplit[1], 10),
        };
      }
      return {};
    }

    function clickCardContainer() {
      secureFieldsRef.value.focus('cardNumber');
    }

    function clickCvvContainer() {
      secureFieldsRef.value.focus('cvv');
    }

    function resize() {
      secureFieldsRef.value.setStyle(
        'cardNumber',
        `border-radius: 0; -webkit-appearance: none; padding: 0; color: #4a4a50; ${window.innerWidth >= 600 ? 'font-size: 1.4rem; line-height: 1.8rem; padding: 24px;' : 'font-size: 1.0rem; line-height: 1.4rem; padding: 16px;'}`,
      );
      secureFieldsRef.value.setStyle(
        'cvv',
        `border-radius: 0; -webkit-appearance: none; padding: 0; color: #4a4a50; ${window.innerWidth >= 600 ? 'font-size: 1.4rem; line-height: 1.8rem; padding: 24px;' : 'font-size: 1.0rem; line-height: 1.4rem; padding: 16px;'}`,
      );
    }

    onMounted(() => {
      const secureFields = new window.SecureFields();
      secureFieldsRef.value = secureFields;

      secureFields.initTokenize(
        props.merchantId,
        {
          cardNumber: {
            placeholderElementId: 'card-number',
            inputType: 'tel',
            placeholder: '4242 4242 4242 4242',
          },
          cvv: {
            placeholderElementId: 'cvv-number',
            inputType: 'tel',
            placeholder: '123',
          },
        },
        {
          focus: 'cardNumber',
        },
      );

      window.addEventListener('resize', resize);

      secureFieldsRef.value.on('ready', () => {
        resize(); // to apply styles
      });

      secureFieldsRef.value.on('change', (data) => {
        hasErrors.value = data.hasErrors;
        cardHasError.value = false;
        cvvHasError.value = false;
        paymentMethod.value = data.fields.cardNumber.paymentMethod;
      });

      secureFields.on('validate', (data) => {
        if (data.fields.cardNumber.valid) {
          cardHasError.value = false;
        } else {
          paymentMethod.value = null;
          cardHasError.value = true;
        }

        if (data.fields.cvv.valid) {
          cvvHasError.value = false;
        } else {
          cvvHasError.value = true;
        }
      });

      secureFields.on('success', ({ transactionId }) => {
        emit('finished', {
          transactionId,
          ...parseExpiryDate(),
          expireDate: expiry.value.replaceAll(' ', ''),
        });
      });

      setInputFilter(expiryRef.value, (value) => /^\d{0,2}[/]?\d{0,2}/.test(value));
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize);
      secureFieldsRef.value.destroy();
    });

    function submit() {
      if (expiry.value !== '') {
        secureFieldsRef.value.submit(parseExpiryDate());
      } else {
        expiryHasError.value = true;
      }
    }

    const cardImage = computed(() => {
      if (cardHasError.value) {
        return CardError;
      }
      if (paymentMethod.value) {
        // eslint-disable-next-line
        return require(`@/assets/brands/${paymentMethod.value}.svg`);
      }
      return CardEmpty;
    });

    const cvvImage = computed(() => {
      if (cvvHasError.value) {
        return CvvError;
      }
      return CvvEmpty;
    });

    const expiryImage = computed(() => {
      if (expiryHasError.value) {
        return CvvError;
      }
      return null;
    });

    return {
      cardImage,
      cvvImage,
      expiryImage,
      paymentMethod,
      hasErrors,
      cardHasError,
      cvvHasError,
      expiryHasError,
      expiryRef,
      expiry,
      submit,
      clickCardContainer,
      clickCvvContainer,
      t,
    };
  },
});
</script>

<style scoped>
.ccForm {
  width: 100%;
  margin: 20px;
}

.card-number,
.expiry,
.cvv {
  height: 40px;
}

.expiry-container,
.cvv-container {
  margin-top: 20px;
}

.submit-container {
  margin-top: 30px;
}

.cvv-container {
  margin-left: 20px;
}

label {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.25rem;
}

button {
  border: none;
  background-color: #06bd6e;
  color: white;
  padding: 14px 24px;
  font-size: 1.4rem;
  cursor: pointer;
}

.sf.sf__base.sf__input .sf--input {
  display: inline-block;
  width: 100%;
  line-height: 1.3rem;
  font-size: 1.0rem;
  cursor: text;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transition-property: color, border-color, background-color, opacity, transform;
}

.expiry-container .sf--input {
  padding: 16px;
  -webkit-font-smoothing: auto;
}

.sf.sf__base.sf__has-card-icon .sf--action.sf--action__card-icon {
  width: 40px;
  height: 100%;
  padding: 0;
  pointer-events: none;
}

@media only screen and (min-width: 600px)  {
  .ccForm {
    margin: 40px;
  }

  .card-number,
  .expiry,
  .cvv {
    height: 80px;
  }

  .expiry-container,
  .cvv-container {
    margin-top: 40px;
  }

  .submit-container {
    margin-top: 50px;
  }

  .cvv-container {
    margin-left: 40px;
  }

  label {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 3rem;
  }

  button {
    padding: 24px 40px;
    font-size: 2.4rem;
  }

  .sf.sf__base.sf__input .sf--input {
    line-height: 1.8rem;
    font-size: 1.4rem;
  }

  .expiry-container .sf--input {
    padding: 24px;
  }

  .sf.sf__base.sf__has-card-icon .sf--action.sf--action__card-icon {
    width: 80px;
  }
}

.sf__row {
  display: flex;
  flex-direction: row;
}

.expiry-container,
.cvv-container {
  flex: 1;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.sf * {
  box-sizing: border-box;
}

.sf.sf__base {
  display: flex;
  flex-flow: column nowrap;
}

.sf.sf__base .sf--input-container {
  position: relative;
  display: flex;
}

.sf.sf__base.sf__input .sf--input-container {
  position: relative;
}

.sf.sf__base.sf__input .sf--input::-moz-placeholder,
.sf.sf__base.sf__input .sf--input:-ms-input-placeholder,
.sf.sf__base.sf__input .sf--input::-webkit-input-placeholder,
.sf.sf__base.sf__input .sf--input::-ms-input-placeholder,
.sf.sf__base.sf__input .sf--input::placeholder {
  transition: color 200ms ease;
}

.sf.sf__base.sf__input .sf--input {
  color: #4a4a50;
  background-color: #ffffff;
  border-color: #c6c6cb;
}

.sf.sf__base.sf__input .sf--input::-moz-placeholder,
.sf.sf__base.sf__input .sf--input:-ms-input-placeholder,
.sf.sf__base.sf__input .sf--input::-webkit-input-placeholder,
.sf.sf__base.sf__input .sf--input::-ms-input-placeholder,
.sf.sf__base.sf__input .sf--input::placeholder {
  color: #c6c6cb;
}

.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input {
  color: #c6c6cb;
  cursor: default;
  background-color: pink;
  border-color: pink;
}

.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input {
  user-select: none;
}

.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::-moz-selection,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input ::-moz-selection,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::selection,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input ::selection {
  background-color: transparent;
}

.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::-moz-placeholder,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input:-ms-input-placeholder,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::-webkit-input-placeholder,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::-ms-input-placeholder,
.sf.sf__base.sf__input.sf__is-disabled.sf .sf--input::placeholder {
  color: transparent;
}

.sf.sf__base.sf__has-actions .sf--actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
  pointer-events: none;
  justify-content: flex-end;
  align-items: center;
}

.sf.sf__base.sf__has-actions .sf--action {
  position: relative;
  display: flex;
  width: 40px;
  height: 100%;
  padding: 12px 0 10px;
  pointer-events: auto;
  cursor: pointer;
  transition: color 200ms ease;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.sf.sf__base.sf__has-card-icon .sf--action-card-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.sf.sf__base.sf__has-card-icon .sf--card-icon {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  max-width: 100%;
  transform: translate3d(0, -50%, 0);
}
</style>

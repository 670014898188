function sendMessage(message) {
  window.parent.postMessage(message, '*');
}

function createMessage(type, payload) {
  return {
    action: type,
    payload,
  };
}

function send(type, payload) {
  sendMessage(
    createMessage(type, payload),
  );
}

export default {
  send,
};

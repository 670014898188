<template>
  <div class="container">
    <CreditCardForm
      :merchant-id="merchantId"
      @finished="finished"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch } from 'vue';

import CreditCardForm from '@/components/CreditCardForm';
import messaging from '@/services/messaging';
import { changeLanguage, t } from '@/i18n';

export default defineComponent({
  name: 'App',
  components: {
    CreditCardForm,
  },
  setup() {
    const merchantId = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'merchant').shift();
        if (v) {
          return v[1];
        }
      }
      return '1110008469'; // dev fallback
    });

    const language = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        let l = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'systemLanguage')?.shift();

        if (l) {
          l = l[1].toLowerCase();
          if (['ger', 'eng', 'fre'].includes(l)) {
            return l;
          }
        }
      }
      return 'ger'; // fallback
    });

    watch(language, () => {
      changeLanguage(language.value);
    }, { immediate: true });

    onMounted(() => {
    });

    function finished(data) {
      messaging.send('set_variable', data);
      messaging.send('close');
    }

    return {
      merchantId,
      finished,
    };
  },
});
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
